import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import dayjs from "dayjs";
import PortableContent from "../components/PortableCotent";
import PortableText from "react-portable-text";
import Layout from "../components/layouts";
import Seo from "../components/seo";

// const parseURL = (ref) => {
//   if (ref) {
//     const split = ref.split("-");

//     if (split.length === 4) {
//       return `https://cdn.sanity.io/images/k1yyj6nw/production/${split[1]}-${split[2]}.${split[3]}`;
//     }
//     return "";
//   } else {
//     return "";
//   }
// };

export default function BlogPost({ data: { sanityPost } }) {
  return (
    <Layout>
      <Seo data={sanityPost?.seo} />
      <div className="tb-container px-5 my-[100px]">
        <div className="grid grid-cols-1 md:grid-cols-12 mb-10 gap-10">
          <div
            className={`${
              sanityPost?.mainImage ? "md:col-span-5" : "md:col-span-12"
            } `}
          >
            <p className="uppercase font-semibold text-secondary">
              Published {dayjs(sanityPost?.publishedAt).format("MMMM-DD-YYYY")}
            </p>
            <p className="text-3xl md:text-[50px] font-bold text-assent leading-9 md:leading-[60px] my-5">
              {sanityPost?.title}
            </p>
            <p className="text-base md:text-xl font-semibold text-secondary">
              {/* {sanityPost?.description} */}
              {sanityPost._rawDescription && (
                <PortableText
                  content={sanityPost._rawDescription}
                  serializers={{
                    link: () => {
                      return <></>;
                    },
                  }}
                />
              )}
            </p>
            {sanityPost?.category?.length > 0 && (
              <div className="font-poppins mt-4 text-xs uppercase font-bold">
                Category:{" "}
                {sanityPost?.category?.map((item, index) => (
                  <span key={index}>
                    <span className="font-primary">{item.title}</span>
                    {sanityPost?.category?.length - 1 !== index && ", "}
                  </span>
                ))}
              </div>
            )}
            <div className="flex items-center mt-10">
              <div className="relative border min-h-[70px] min-w-[70px] max-w-[70px] max-h-[70px] rounded-full overflow-hidden">
                <GatsbyImage
                  image={sanityPost?.author[0]?.image?.asset?.gatsbyImageData}
                  alt={sanityPost?.author[0]?.image?.alt}
                  className="rounded-full min-h-[70px] min-w-[70px] max-w-[70px] max-h-[70px]"
                />
              </div>
              <div className="ml-2">
                <p className="text-base text-assent font-semibold">
                  {sanityPost.author[0]?.name}
                </p>
                <p className="text-secondary text-sm">
                  {sanityPost.author[0]?.bio}
                </p>
              </div>
            </div>
          </div>
          {sanityPost?.mainImage && (
            <div className="md:col-span-7">
              <div className="relative">
                <GatsbyImage
                  image={sanityPost?.mainImage?.asset?.gatsbyImageData}
                  alt={sanityPost?.mainImage?.alt}
                />
              </div>
            </div>
          )}
        </div>
        <hr />
        <div className="w-full md:w-3/4 mx-auto px-5 md:px-0 mt-5">
          {sanityPost?._rawBody && (
            <div className="blog-text font-semibold w-full text-[#323A44] block-content max-w-none">
              <PortableContent content={sanityPost._rawBody} />
            </div>
            // <PortableText
            //   content={sanityPost._rawBody}
            //   className="blog-text font-semibold w-full text-[#323A44] block-content max-w-none"
            //   projectId={"k1yyj6nw"}
            //   dataset={"production"}
            //   serializers={{
            //     figure: ({ asset, alt }) => {
            //       return <img src={parseURL(asset._ref)} alt={alt} />;
            //     },
            //     code: ({ children }) => {
            //       return (
            //         <div
            //           dangerouslySetInnerHTML={{
            //             __html: children,
            //           }}
            //         />
            //       );
            //     },
            //   }}
            // />
          )}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($blogId: String!) {
    sanityPost(id: { eq: $blogId }) {
      seo {
        description
        keywords
        ldSchema
        pagehandle
        title
      }
      _rawDescription
      _rawBody
      id
      title
      category {
        title
      }
      mainImage {
        alt
        asset {
          gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
        }
      }
      publishedAt
      slug {
        current
      }
      author {
        name
        bio
        image {
          alt
          asset {
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, formats: WEBP)
          }
        }
      }
    }
  }
`;
