import React from "react";
import { PortableText } from "@portabletext/react";
import { getImageDimensions } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder({
  projectId: "k1yyj6nw",
  dataset: "production-v1",
});

export default function PortableContent({ content }) {
  return (
    <PortableText
      value={content}
      components={{
        marks: {
          color: (item) => (
            <span style={{ color: item?.value?.hex }}>{item?.children}</span>
          ),
        },

        types: {
          figure: ({ value }) => {
            const { width, height } = getImageDimensions(value);

            return (
              <>
                <div className="flex justify-center blog-img">
                  <img
                    className="shadow-md"
                    src={builder.image(value?.asset?._ref)}
                    alt={value?.alt}
                    width={width}
                    height={height}
                    style={{
                      marginBottom:
                        value.attribution && value.attribution.length > 0
                          ? 0
                          : 15,
                      marginTop: 15,
                    }}
                  />
                </div>
                {value.attribution && value.attribution.length > 0 && (
                  <div className="mt-2 mb-4 text-sm leading-6 text-[#676a6b] text-center attribution">
                    <PortableText value={value.attribution} />
                  </div>
                )}
              </>
            );
          },

          embedCode: ({ value }) => (
            <div className="pt-1">
              <div
                className="flex justify-center"
                dangerouslySetInnerHTML={{ __html: value?.code }}
              ></div>
              {value.attribution && value.attribution.length > 0 && (
                <div className="my-4 text-sm leading-6 text-[#676a6b] text-center">
                  <PortableText value={value.attribution} />
                </div>
              )}
            </div>
          ),
          video: ({ value }) => (
            <div className="py-2">
              <div
                className="video-container"
                dangerouslySetInnerHTML={{ __html: value?.embededCode }}
              ></div>
              {value.attribution && value.attribution.length > 0 && (
                <div className="my-4 text-sm leading-6 text-[#676a6b] text-center">
                  <PortableText value={value.attribution} />
                </div>
              )}
            </div>
          ),
        },
        list: {
          bullet: ({ children }) => (
            <ul className="relative list-none">{children}</ul>
          ),
        },
        listItem: {
          bullet: (item) => (
            <li className="relative mx-0 my-1 before:content-[''] before:absolute before:top-[6px] before:-left-[14px] before:h-[6px] before:w-[6px] before:rounded-full before:bg-black before:inline-block">
              {item?.children}
            </li>
          ),
        },
      }}
    />
  );
}
